<template>
    <div>
        <div class="d-flex justify-content-end">
            <b-pagination
                v-if="count > 0"
                v-model="currentPage"
                :total-rows="count"
                :per-page="perPage"
                first-text="<<"
                prev-text="<"
                next-text=">"
                last-text=">>"
            ></b-pagination>
        </div>

    <div class="background-light">
        <b-table small 
            :currentPage="currentPage"
            :per-page="perPage"
            :fields="fields" 
            :items="userList" 
            
            head-variant="light" 
            responsive="sm" 
            >
            <template #cell(actions)="data">
                <div class="d-flex justify-content-end">
                <b-button-group  >
                    <b-button size="sm" variant="light" @click.prevent="editUser(data.item.id)">
                        <b-icon icon="pencil" aria-hidden="true" :title="$t('edit')"></b-icon>
                        <span class="d-none d-xl-inline"> {{$t('edit')}} </span>
                    </b-button>
                    <b-button size="sm" variant="light" @click.prevent="removeUser(data.item.id)">
                        <b-icon icon="trash-fill" aria-hidden="true" :title="$t('delete')"></b-icon>
                        <span class="d-none d-xl-inline"> {{$t('delete')}}</span>
                    </b-button>
                    <!--<b-dropdown size="sm" variant="light" right text="Split Menu">
                        <template #button-content>
                            <b-icon icon="three-dots-vertical" title="Actions"/>
                        </template>
                        <b-dropdown-item>Item 1</b-dropdown-item>
                        <b-dropdown-item>Item 2</b-dropdown-item>
                        <b-dropdown-divider></b-dropdown-divider>
                        <b-dropdown-item>Item 3</b-dropdown-item>
                    </b-dropdown>-->
                </b-button-group>
                </div>
            </template>
            <template #cell(devCount)="data">
                {{ data.item.devices.length }}
            </template>
            <!-- Optional default data cell scoped slot -->
            <template #cell()="data">
                {{ data.value }}
            </template>
        </b-table>
    </div> 
    </div>   
</template>

<script>
//head-variant="light" striped
//            table-variant="light" 

//        <b-form-select id="user" v-model="selected" :options="userList"></b-form-select>

export default {
    name: 'UserList',
    data() {
      return {
        currentPage: 1, 
        perPage: 20,
        rows: 0, 
        fields: [
            'id',
            { 
                key: 'email', 
                label: this.$t('email') 
            },
            { 
                key: 'userRole', 
                label: this.$t('role') 
            },
            { 
                key: 'isConfirmed', 
                label: this.$t('confirmed'), 
                formatter: value => {
                    return value ? this.$t('yes'):this.$t('no')
                }
            },
            { 
                key: 'devCount', 
                label: this.$t('num_of_devices'), 
            },
            { 
                key: 'actions', 
                label: '' 
            }
        ],
      }
    },
    computed: {
        count(){
            var cnt
            if (this.userList)
                cnt = this.userList.length
            else
                cnt = 0        
            console.log('userList count', cnt)    
            return cnt
        },
    },
    props: ['userList'],
    methods: {
        editUser(id) {
            console.log('edit user',id)
            //console.log(event.target.value)
            this.$emit('editUser',id)
            //alert('Edit user id:'+id + ' not implemented')
        },
        removeUser(id){
            console.log('Remove user',id)
            //this.$emit('removeUser',id)
            if (id) {
                if (confirm(this.$t('user_remove_q',{id:id}))) {
                    this.$store.dispatch("delete_user", id)
                    .then(success => {
                        console.log(success)
                        alert(this.$t('user_deleted'))
                    })
                    .catch(error => {
                        console.log(error)
                        alert(error)
                        //this.error = 'Invalid username/password!'
                    })
                }
            }
        }
        /*onChange(event) {
            console.log(event)
            //console.log(event.target.value)
            this.$emit('userSelected',event)
        }*/
    }    
}
</script>
<style>
  .action-block-col {
    display: inline-block;
  }
</style>