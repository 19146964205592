<template>
    <div class="content">
        <h2>{{$t('user_settings')}}</h2>
        <h3 v-if="!user">You are not logged in!</h3>
        <!--<b-button class="mt-3" @click.prevent="addUser" variant="light">Add new user</b-button>-->
        <UserTable class="mt-3" :userList="userList" @editUser="onUserEdit" @removeUser="onRemoveUser"/>
        <!--<UserList class="mt-2" :userList="userList" @editUser="onUserEdit" @removeUser="onRemoveUser"/>-->
        <b-modal ref="modal-1" id="modal-1" :title="modalTitle" @ok="handleOk"> 
           <UserEdit ref="edit-user" :user="selectedUser" :action="action"/> 
        </b-modal>
        
    </div>
</template>
<script>
import {mapGetters} from 'vuex'
//import UserList from './UserList.vue'
import UserTable from './UserTable.vue'
import UserEdit from './UserEdit.vue'

export default {
    name: 'UserSetup',
    components: {
        //UserList,
        UserTable,
        UserEdit
    },
    data() {
        return {
            //users: [],
            selectedUser: this.newUser(),
            simList: [],
            action: 'add',
            name:'',
        }
    },
    computed: {
        ...mapGetters(['user']),
        ...mapGetters(['userList']),
        count(){
            return this.users.length
        },
        modalTitle(){
            return (this.action == 'add')?this.$t('add_user'):this.$t('edit_user')
        }
    },
    methods: {
        newUser() {
            return {
                name:'',
                userType: 0,
                sims:[],
            }
        },
        showModal() {
            this.$refs['modal-1'].show()
        },
        hideModal() {
            this.$refs['modal-1'].hide()
        },
        handleOk(bvModalEvt){
            // Prevent modal from closing
            bvModalEvt.preventDefault()
            // Trigger submit handler
            if (this.$refs['edit-user'].handleSubmit()) {
                this.$nextTick(() => {
                    this.hideModal()
        })
            }
        },
        addUser(){
            this.action = 'add'
            this.selectedUser = this.newUser()
            //this.$store.dispatch("clear_sim_list")
            this.showModal()
        },
        onUserEdit: function (id) {
            console.log(id)
            //this.$data.selectedUser = id
            var user = this.userList.find(obj => obj.id == id)
            if (user) {
                this.selectedUser = user
                this.action = 'edit'
                console.log('user to edit', user)
                /*this.$store.dispatch("get_sim_list", id)
                .then(success => {
                    console.log(success)
                    //alert('success')
                    this.showModal()
                    //this.$router.push("/")
                })
                .catch(error => {
                    console.log(error)
                    alert(error)
                    //this.error = 'Invalid username/password!'
                })*/
                this.showModal()
            }
        },
        onRemoveUser: function (id) {
            console.log(id)
            //this.$data.selectedUser = id
            if (id) {
                this.$store.dispatch("delete_user", id)
                .then(success => {
                    console.log(success)
                    //alert('success')
                    alert(this.$t('user_deleted'))
                    //this.$router.push("/")
                })
                .catch(error => {
                    console.log(error)
                    alert(error)
                    //this.error = 'Invalid username/password!'
                })
            }
        },
        handleSubmit() {
            this.$store.dispatch("create_user", {
                name: this.name,
                //userType: this.userType
            })
            .then(success => {
                console.log(success)
                this.getUsers()
                alert(this.$t('user_created'))
                //this.$router.push("/")
            })
            .catch(error => {
                console.log(error)
                alert(error)
                //this.error = 'Invalid username/password!'
            })
        },
        /*onUserSelected: function (id) {
            console.log(id)
            //this.$data.selectedUser = id
            this.loadUserDetails(id)
        },
        async loadUserDetails(id){
            try {
                const response = await axios.get('Sensor/DataByUserId/'+id)
                console.log(response);
                this.$data.selectedUser = id
                this.$data.sensorData = response.data
                console.log('sensorData:', response.data)
                //localStorage.setItem('token', response.data.token)
                //this.$store.dispatch('user', {email:'',logged:true})
                //this.$router.push('/')
            } catch (e) {
                this.$router.push('/login')
                //this.error = 'Invalid username/password!'
            }

        }*/
        getUsers(){
            this.$store.dispatch("get_user_list")
            .then(success => {
                console.log('loaded user data', success)
                //this.$router.push("/")
                //this.$store.dispatch('set_user', {email:'',logged:true})
            })
            .catch(error => {
                console.log('failed to load user data', error)
                this.$router.push('/login')
                //this.error = true;
            })
        }
    },
    async created() {
            this.getUsers()
            /*try {
                const response = await axios.get('user')
                console.log(response);
                this.$data.users = response.data
                //localStorage.setItem('token', response.data.token)
                this.$store.dispatch('set_user', {email:'',logged:true})
                //this.$router.push('/')
            } catch (e) {
                this.$router.push('/login')
                //this.error = 'Invalid username/password!'
            }*/
        }
}
</script>
