<template>
    <div>
        <form ref="form" @submit.prevent="handleSubmit">
            <b-form-group
                :label="$t('email')"
                label-for="email-input"
                :invalid-feedback="$t('email_required')"
                :state="nameState"
                >
            
                <b-form-input type="text" class="form-control" v-model="user.email" :placeholder="$t('user_email')" required/>
            </b-form-group>

            <b-form-group
                :label="$t('timezone')"
                label-for="timezone-input"
                :invalid-feedback="$t('timezone_required')"
                :state="nameState"
                >
            
                <b-form-select id="timeZone" name="timeZones" v-model="user.timeZone" @change="onChange($event)">
                    <b-form-select-option :value="null" disabled>{{$t('select_timezone')}}</b-form-select-option>
                    <b-form-select-option :value="timeZone.id" v-for="timeZone in timeZones" :key="timeZone.id">{{timeZone.displayName}} ({{timeZone.id}})</b-form-select-option>
                </b-form-select>
            </b-form-group>

        </form>
    </div>
</template>
<script>
/*<h3 v-if="action == 'add'">Add User</h3>
  <h3 v-else>Edit User</h3>
<b-button type="submit" variant="primary">Submit</b-button>*/
//<h3 v-if="!user">You are not logged in!</h3>
//<h3 v-if="user">{{count}}</h3>
//<UserList :userList="users" v-on:userSelected="onUserSelected"/>

import {mapGetters} from 'vuex'
export default {
    name: 'UserEdit',
    components: {
    },
    data() {
        return {
            selectedUser: null,
            name:'',
            nameState: null,
            
        }
    },
    props: [
        'user',
        'action'
        ],
    computed: {
        //...mapGetters(['user']),
        ...mapGetters(['timeZones']),
        count(){
            return this.users.length
        }
    },
    methods: {
        checkFormValidity() {
            const valid = this.$refs.form.checkValidity()
            this.nameState = valid
            return valid
        },
        handleSubmit() {
            if (!this.checkFormValidity()) {
                return false
            }
            
            var payload = {
                //name: this.user.name,
                //userType: this.user.userType
                timeZone: this.user.timeZone,
                email: this.user.email

            }
            if (this.action == 'add') {
                var method = 'create_user'
            } else {
                method = 'update_user'
                payload.id = this.user.id
//                console.log('Cannot update user')
                //return true
            }
            this.nameState = null
            console.log('user submit payload', payload, method)
            this.$store.dispatch(method, payload)
            .then(success => {
                console.log(success)
                if (method == 'create_user')
                    alert(this.$t('user_added'))
                else     
                    alert(this.$t('user_updated'))
                //this.$router.push("/")
            })
            .catch(error => {
                console.log(error)
                alert(error)
                //this.error = 'Invalid username/password!'
            })
            return true
        },
        
        
        getUsers(){
            this.$store.dispatch("get_user_list")
            .then(success => {
                console.log('loaded user data', success)
                //this.$router.push("/")
                this.$store.dispatch('set_user', {email:'',logged:true})
            })
            .catch(error => {
                console.log('failed to load user data', error)
                this.$router.push('/login')
                //this.error = true;
            })
        },
        getTimeZones(){
            this.$store.dispatch("get_timezones")
            .then(success => {
                console.log('loaded timezones', success)
            })
            .catch(error => {
                console.log('failed to load timezones', error)
            })
        },
        onChange(event){
            console.log(event)
        }
    },
    async created() {
            this.getTimeZones()
        }
}
</script>
